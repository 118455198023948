'use client';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export function ProductsSlider({children}: {children: React.ReactNode}){
    const settings = {
        infinite: true,
        speed: 500,
        autoplay: false,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        nextArrow: <RightControl />,
        prevArrow: <LeftControl />,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    return(
        <ol className='w-full [&_.slick-slide]:h-full'>
            <Slider {...settings}>
                {children}
            </Slider>
        </ol>
    )
}
interface ControlProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
}

function LeftControl (props: ControlProps){
    const { className, style, onClick } = props;
    return (
        <div className="z-30 left-0 absolute top-[45%] translate-y-1/2 cursor-pointer text-fp-gray " onClick={onClick} >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 md:size-12">
                <path fillRule="evenodd" d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z" clipRule="evenodd" />
            </svg>
        </div>
    )
}
function RightControl (props: ControlProps) {
    const { className, style, onClick } = props;
    return (
        <div onClick={onClick} className="z-30 right-1 absolute top-[45%] translate-y-1/2 cursor-pointer text-fp-gray">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6 md:size-12">
                <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
            </svg>
        </div>
    )
}